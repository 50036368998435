import { GetStaticProps } from "next";
import { ElementService } from "../../services/base/seo/page/element";

export const getStaticProps: GetStaticProps = async () => {
  const [seoAdmin] = await Promise.all([
    ElementService({
      path: `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/international-hotel`
    }) || null
  ]);

  return {
    revalidate: 600,
    props: {
      seoAdmin
    }
  };
};
